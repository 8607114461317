/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as emailValidator from 'email-validator';
import { Button, Icon } from 'semantic-ui-react';
import style from './distributionListSelection.style';
import { AdUser, Contact } from '../../../users/model/user.model';
import { AdUserDropdownField } from '../../../users/components/AdUserDropdownField';

interface AssessmentFieldProps {
  defaultValue?: Contact[];
  onEdited?: (values: Contact[]) => void;
}

export function DistributionListSelection({
  defaultValue = [],
  onEdited = () => null,
}: AssessmentFieldProps): JSX.Element {
  const [inputState, setInputState] = React.useState({ name: '', email: '', jobTitle: '' });
  const { t } = useTranslation();

  const addEmail = () => {
    onEdited([...defaultValue, inputState]);
    setInputState({ name: '', email: '', jobTitle: '' });
  };

  const removeEmail = (email: string) => {
    onEdited(defaultValue?.filter(c => c.email !== email));
  };

  const onAdUserChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setInputState({
        name: `${selectedUser.firstName} ${selectedUser.familyName}`,
        email: selectedUser.email,
        jobTitle: selectedUser.jobTitle || '',
      });
    } else {
      setInputState({ name: '', email: '', jobTitle: '' });
    }
  };

  function isValidEmail() {
    return (
      defaultValue != null &&
      inputState.email !== '' &&
      emailValidator.validate(inputState.email) &&
      !defaultValue.includes(inputState)
    );
  }

  return (
    <div>
      <div css={style.inputContainer}>
        <AdUserDropdownField
          placeholder={t('users.search') || ''}
          onChange={onAdUserChange}
          cornerIcon='search'
          value={inputState.email}
          showJobTitle
        />

        <Button onClick={addEmail} disabled={!isValidEmail()} icon>
          <Icon name='plus'> </Icon>
        </Button>
      </div>
      <div css={style.resultContainer}>
        {defaultValue.map(contact => (
          <div css={style.checkBoxContainer} key={contact.email}>
            <Icon name='mail' />
            <div css={style.distributionListMemberInfo}>
              <div>{contact.email}</div>
              <div css={style.distributionListMemberName}>{contact.name}</div>
              <div css={style.distributionListMemberName}>{contact.jobTitle}</div>
            </div>
            <Button icon onClick={() => removeEmail(contact.email)}>
              <Icon name='remove' />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
