/** @jsxImportSource @emotion/react */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Radio } from 'semantic-ui-react';
import style from './assessmentsBulkUpdateWizard.style';
import { AssessmentBulkUpdateWizardField, AssessmentBulkUpdateWizardStep } from '../../model/assessmentBulkUpdate';
import {
  selectActionPlanCoordinatorsSelectedAssessments,
  selectAuditorsSelectedAssessments,
  selectDistributionListSelectedAssessments,
  selectReadOnlyDistributionListSelectedAssessments,
} from '../../store/assessmentBulkUpdateSlice';

interface SelectValueToReplaceStepProps {
  onClose: () => void;
  setStep: (step: AssessmentBulkUpdateWizardStep) => void;
  field: AssessmentBulkUpdateWizardField | null;
  valueToReplace: string | null;
  setValueToReplace: (valueToReplace: string | null) => void;
}

interface SelectItem {
  value: string;
  description: string;
  subdescription?: string;
  jobTitle?: string;
}

export const SelectValueToReplaceStep = ({
  onClose,
  setStep,
  field,
  valueToReplace,
  setValueToReplace,
}: SelectValueToReplaceStepProps): JSX.Element => {
  const { t } = useTranslation();

  const auditors = useSelector(selectAuditorsSelectedAssessments);
  const actionPlanCoordinators = useSelector(selectActionPlanCoordinatorsSelectedAssessments);
  const distributionList = useSelector(selectDistributionListSelectedAssessments);
  const readOnlyDistributionList = useSelector(selectReadOnlyDistributionListSelectedAssessments);

  const getValues = (): SelectItem[] => {
    switch (field) {
      case AssessmentBulkUpdateWizardField.AUDITORS:
        return auditors.map(auditor => {
          return { value: auditor.email, description: auditor.name };
        });
      case AssessmentBulkUpdateWizardField.ACTION_PLAN_COORDINATORS:
        return actionPlanCoordinators.map(actionPlanCoordinator => {
          return {
            value: actionPlanCoordinator.email,
            description: actionPlanCoordinator.name,
            subdescription: actionPlanCoordinator.role,
          };
        });
      case AssessmentBulkUpdateWizardField.DISTRIBUTION_LIST:
        return distributionList.map(contact => {
          return {
            value: contact.email,
            description: contact.email,
            subdescription: contact.name,
            jobTitle: contact.jobTitle,
          };
        });
      case AssessmentBulkUpdateWizardField.READ_ONLY_DISTRIBUTION_LIST:
        return readOnlyDistributionList.map(contact => {
          return {
            value: contact.email,
            description: contact.email,
            subdescription: contact.name,
            jobTitle: contact.jobTitle,
          };
        });
      default:
        return [];
    }
  };

  const onClickPrev = () => {
    setValueToReplace(null);
    setStep(AssessmentBulkUpdateWizardStep.SELECT_ACTION);
  };

  return (
    <>
      <Modal.Header>
        <div css={style.header}>
          <div>{t('assessment.bulkUpdate.wizard.steps.SELECT_VALUE_TO_REPLACE')}</div>
          <div css={style.headerIcon} onClick={onClose}>
            <Icon className='fas fa-close' />
          </div>
        </div>
      </Modal.Header>

      <Modal.Content css={style.modalContent}>
        {getValues().length > 0 ? (
          <div css={style.radioGroup}>
            {getValues().map(item => (
              <div css={item.subdescription ? style.radioButtonSubdescription : style.radioButton}>
                <Radio
                  css={item.subdescription && style.radioCheckbox}
                  checked={item.value === valueToReplace}
                  onClick={() => setValueToReplace(item.value)}
                />
                <div css={style.radioLabel}>
                  <div>{item.description}</div>
                  {item.subdescription && <div css={style.radioSubdescription}>{item.subdescription}</div>}
                  {item.jobTitle && <div css={style.radioSubdescription}>{item.jobTitle}</div>}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div css={style.noValuesLabel}>{t('assessment.bulkUpdate.wizard.noValuesToReplace')}</div>
        )}
      </Modal.Content>
      <Modal.Actions css={style.actions}>
        <Button css={style.button} onClick={onClickPrev}>
          {t('assessment.bulkUpdate.wizard.buttons.prev')}
        </Button>
        <Button
          css={style.button}
          onClick={() => setStep(AssessmentBulkUpdateWizardStep.SELECT_NEW_VALUE)}
          disabled={valueToReplace == null}>
          {t('assessment.bulkUpdate.wizard.buttons.next')}
        </Button>
      </Modal.Actions>
    </>
  );
};
