import { css, SerializedStyles } from '@emotion/react';
import style from './field.style';

export type NoteType =
  | 'manager'
  | 'auditor'
  | 'auditorFollowUp'
  | 'brand'
  | 'auditManagerNote'
  | 'brandNote'
  | 'auditorNote'
  | 'regionalManagerNote';

const fieldBackgroundColor: Record<NoteType, string> = {
  manager: 'var(--saa-note);',
  auditor: 'var(--saa-auditor-note);',
  auditorFollowUp: 'var(--saa-auditor-note-follow-up);',
  brand: 'var(--saa-brand-note);',
  auditManagerNote: 'var(--saa-brand-note-light);',
  brandNote: 'var(--saa-brand-note-light-green);',
  auditorNote: 'var(--saa-brand-note-light-violet);',
  regionalManagerNote: 'var(--saa-regional-manager-note);',
};

const fieldBorderColor: Record<NoteType, string> = {
  manager: 'var(--saa-note-dark) var(--saa-primary-color-10);',
  auditor: 'var(--saa-auditor-note-dark) var(--saa-primary-color-10);',
  auditorFollowUp: 'var(--saa-auditor-note-follow-up-dark) var(--saa-primary-color-10);',
  brand: 'var(--saa-brand-note-dark) var(--saa-primary-color-10);',
  auditManagerNote: 'var(--saa-note-dark) var(--saa-primary-color-10);',
  brandNote: 'var(--saa-success-dark) var(--saa-primary-color-10);',
  auditorNote: 'var(--saa-auditor-note-dark) var(--saa-primary-color-10);',
  regionalManagerNote: 'var(--saa-regional-manager-note-dark) var(--saa-primary-color-10);',
};

const headerBackgroundColor: Record<NoteType, string> = {
  manager: 'var(--saa-note);',
  auditor: 'var(--saa-auditor-note-dark);',
  auditorFollowUp: 'var(--saa-auditor-note-follow-up-dark);',
  brand: 'var(--saa-brand-note-dark);',
  auditManagerNote: 'var(--saa-brand-note-light);',
  brandNote: 'var(--saa-brand-note-light-green);',
  auditorNote: 'var(--saa-auditor-note-dark);',
  regionalManagerNote: 'var(--saa-regional-manager-note-dark);',
};

const field = (type: NoteType, disabled = false): SerializedStyles => css`
  ${style.field};
  &&&& {
    position: relative;
    cursor: pointer;
    background-color: ${disabled ? 'var(--saa-primary-color-40)' : fieldBackgroundColor[type]};
    min-height: 50px;
    :before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      border-width: 16px 0 0 16px;
      border-style: solid;
      border-color: ${disabled ? 'var(--saa-primary-color-60) var(--saa-primary-color-10)' : fieldBorderColor[type]};
    }
  }
`;

const note = css`
  margin: 0 10px;
`;
const header = (type: NoteType, disabled = false): SerializedStyles => css`
  background-color: ${disabled ? 'var(--saa-primary-color-40)' : headerBackgroundColor[type]};
  color: var(--saa-primary-color);
  padding: 5px;
  border-bottom: solid 1px var(--saa-primary-color-60);
`;

const editableField = (type: NoteType, disabled = false): SerializedStyles => css`
  ${style.editableField};
  ${field(type, disabled)};
`;

const editIcon = css`
  ${style.editIcon}
`;

const modal = css`
  ${style.modal}
`;

const modalContent = css`
  ${style.modalContent};
`;

const checkBoxContainer = css`
  ${style.checkBoxContainer};
`;

const actions = css`
  display: flex;
  justify-content: flex-end;
`;

const button = css`
  ${style.button}
  flex: 1;
  min-width: max-content;
  max-width: 120px;
`;

const inputContainer = css`
  &&&& {
    textarea {
      border-color: var(--saa-primary-color-10);
      outline: none;
      width: 100%;
      height: 240px;
      max-height: 240px;
      min-width: 250px;
      min-height: 240px;
      overflow: auto;
      resize: none;
    }
  }
`;
const fieldValue = css`
  && {
  }
`;

export default {
  note,
  header,
  field,
  editableField,
  modal,
  modalContent,
  inputContainer,
  actions,
  button,
  checkBoxContainer,
  editIcon,
  fieldValue,
};
