import { css } from '@emotion/react';
import style from '../../../shared/global.style';
import fieldStyle from './field.style';

const body = css`
  &&& {
    width: 100%;
    overflow: auto;
    margin-bottom: 10px;
    flex-grow: 1;
  }
`;

const subProcess = css`
  && {
    display: flex;
    background-color: var(--saa-white-color);
    padding: 10px;
    border-bottom: solid 2px var(--saa-primary-color-10);
    cursor: pointer;
    align-items: center;
    position: relative;
  }
`;

const subProcessActionIcon = css`
  ${fieldStyle.editIcon};
  &&&& {
    position: relative;
  }
`;

const warnIcon = css`
  color: var(--saa-warning);
`;
const successIcon = css`
  color: var(--saa-success);
`;
const errorIcon = css`
  color: var(--saa-error);
`;

const noteIcon = css`
  color: var(--saa-note-dark);
`;

const auditorNoteIcon = css`
  color: var(--saa-auditor-note-dark);
`;

const auditorFollowUpNoteIcon = css`
  color: var(--saa-auditor-note-follow-up-dark);
`;

const regionalManagerNoteIcon = css`
  color: var(--saa-regional-manager-note-dark);
`;

const actionPlanCoordinatorNoteIcon = css`
  color: var(--saa-brand-note-light-green);
`;

const notMandatoryAttachmentsIcon = css`
  color: var(--saa-error-dark);
`;

const subProcessDescription = css`
  ${style.bodySmall}
  && {
    flex: 1;
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
  }
`;

const findingsFilter = css`
  ${style.subtitleSmall}
  margin-bottom: 10px;
  line-height: 1rem;
`;

const noItems = css`
  ${style.subtitleSmall}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default {
  body,
  subProcess,
  subProcessActionIcon,
  warnIcon,
  successIcon,
  subProcessDescription,
  errorIcon,
  noteIcon,
  auditorNoteIcon,
  auditorFollowUpNoteIcon,
  actionPlanCoordinatorNoteIcon,
  findingsFilter,
  noItems,
  regionalManagerNoteIcon,
  notMandatoryAttachmentsIcon,
};
