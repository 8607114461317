/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import style from './assessmentReportTableRow.style';
import { AssessmentReportResult } from '../../model/assessmentReportResult';
import { selectPrincipal } from '../../../auth/store/principalSlice';
import { checkRoles, ROLES } from '../../../auth/model/principal.model';

interface AssessmentReportTableRowProps {
  result: AssessmentReportResult;
  onClickRow?: (result: AssessmentReportResult) => void;
  rowHeight?: number;
  cellTitle?: boolean;
}

export const AssessmentReportTableRow: React.FC<AssessmentReportTableRowProps> = ({
  result,
  onClickRow,
  rowHeight,
  cellTitle = true,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectPrincipal);
  const isAuditor = useCallback(() => checkRoles(user, [ROLES.AUDITOR, ROLES.AUDITOR_MANAGER]), [user]);

  return (
    <Table.Row
      css={style.row}
      onClick={() => onClickRow && onClickRow(result)}
      style={{ cursor: onClickRow ? 'pointer' : 'default', height: rowHeight ?? 'auto' }}
      verticalAlign='bottom'>
      {isAuditor() && (
        <Table.Cell title={cellTitle ? t(`assessment.status.${result.status}`) : ''}>
          {t(`assessment.status.${result.status}`) ?? '-'}
        </Table.Cell>
      )}
      <Table.Cell title={cellTitle ? result.brand.description : ''}>{result.brand.description ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.region : ''}>{result.region ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.country : ''}>{result.country ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.city : ''}>{result.city ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.hfmCode : ''}>{result.hfmCode ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.jdaCode : ''}>{result.jdaCode ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.locationName : ''}>{result.locationName ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.year : ''}>{result.year ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.month : ''}>{result.month ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.processCode : ''}>{result.processCode ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.processDescription : ''}>{result.processDescription ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.subProcessCode : ''}>{result.subProcessCode ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.subProcessDescription : ''}>
        {result.subProcessDescription ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.subProcessResultFindings : ''}>
        <div css={style.wrappedCell}>{result.subProcessResultFindings ?? '-'}</div>
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.subProcessResultPriority : ''}>
        {result.subProcessResultPriority ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.subProcessResultRecommendation : ''}>
        {result.subProcessResultRecommendation ?? '-'}
      </Table.Cell>
      {isAuditor() && (
        <>
          <Table.Cell title={cellTitle ? result.subProcessScore : ''}>{result.subProcessScore ?? '-'}</Table.Cell>
          <Table.Cell title={cellTitle ? result.failedScorePercentage.toFixed(2) : ''}>
            {result.failedScorePercentage ? `${(result.failedScorePercentage * 100).toFixed(2)}%` : '-'}
          </Table.Cell>
          <Table.Cell title={cellTitle ? result.rating : ''}>{result.rating ?? '-'}</Table.Cell>
          <Table.Cell title={cellTitle ? result.successScorePercentage.toFixed(2) : ''}>
            {result.successScorePercentage ? `${(result.successScorePercentage * 100).toFixed(2)}%` : '-'}
          </Table.Cell>
          <Table.Cell title={cellTitle ? result.keyBusinessRisks.join(', ') : ''}>
            {result.keyBusinessRisks.join(', ') || '-'}
          </Table.Cell>
        </>
      )}
      <Table.Cell title={cellTitle ? result.actionPlanDescription : ''}>
        <div css={style.wrappedCell}> {result.actionPlanDescription ?? '-'}</div>
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.actionPlanSupervisor.email : ''}>
        {result.actionPlanSupervisor?.email ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.actionPlanSupervisor.role : ''}>
        {result.actionPlanSupervisor?.role ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.actionPlanDueDate : ''}>{result.actionPlanDueDate ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? t(`assessment.followUpStatus.${result.followUpStatus}`) : ''}>
        {t(`assessment.followUpStatus.${result.followUpStatus}`) ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.followUpResult : ''}>{result.followUpResult ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.followUpDescription : ''}>{result.followUpDescription ?? '-'}</Table.Cell>
      <Table.Cell title={cellTitle ? result.followUpSupervisor?.email : ''}>
        {result.followUpSupervisor?.email ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.followUpSupervisor?.role : ''}>
        {result.followUpSupervisor?.role ?? '-'}
      </Table.Cell>
      <Table.Cell title={cellTitle ? result.followUpDueDate : ''}>{result.followUpDueDate ?? '-'}</Table.Cell>
    </Table.Row>
  );
};
