/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Input, Label, Modal, SemanticICONS } from 'semantic-ui-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { BrandCoordinator } from '../model/assessment';
import { AdUser } from '../../users/model/user.model';
import style from './brandCoordinatorField.style';
import { AdUserDropdownField } from '../../users/components/AdUserDropdownField';
import { selectAssessment } from '../store/assessmentDetailSlice';
import { useCtrlEnterToRunCallback } from '../../../core/utils';
import { BrandCode } from '../../../shared/model/brand.model';

interface BrandCoordinatorProps {
  defaultValue?: BrandCoordinator[];
  editable?: boolean;
  onEdited?: (value: BrandCoordinator[]) => void;
  icon?: SemanticICONS;
  className?: string;
  title: string;
  brand?: BrandCode;
}

export const BrandCoordinatorField = ({
  defaultValue = [],
  editable = false,
  onEdited = () => null,
  icon,
  className = '',
  title,
  brand,
}: BrandCoordinatorProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [coordinators, setCoordinators] = useState(defaultValue);
  const [isValidForm, setIsValidForm] = useState(true);
  const coordinatorLimit = 2;
  const coordinatorLimitReached = useMemo(() => coordinators.length >= coordinatorLimit, [coordinators]);
  const assessment = useSelector(selectAssessment);

  const clearFields = () => {
    setEmail('');
    setName('');
    setRole('');
  };

  const updateValue = () => {
    onEdited(coordinators);
    setOpen(false);
    clearFields();
  };

  const onCancel = () => {
    clearFields();
    setCoordinators(defaultValue);
    setOpen(false);
  };

  const validateCoordinator = (): boolean => {
    if (coordinators.some(coordinator => coordinator.email === email)) {
      return false;
    }
    if (email.trim() === '') {
      return false;
    }
    if (role.trim() === '') {
      return false;
    }

    return true;
  };

  const emailErrorMessage = useMemo((): string => {
    if (coordinators.some(coordinator => coordinator.email === email)) {
      return t('assessment.brandCoordinatorList.error.emailDuplicate');
    }
    if (email.trim() === '') {
      return t('assessment.brandCoordinatorList.error.emailEmpty');
    }
    return '';
  }, [email, coordinators]);

  const roleErrorMessage = useMemo((): string => {
    if (role.trim() === '') {
      return t('assessment.brandCoordinatorList.error.roleEmpty');
    }
    return '';
  }, [role]);

  const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const onAdUserChange = (selectedUser?: AdUser) => {
    if (selectedUser) {
      setEmail(selectedUser.email);
      const fullName = `${selectedUser.firstName} ${selectedUser.familyName}`;
      setName(fullName.trim());
      setRole(selectedUser.jobTitle || '');
    } else {
      setEmail('');
      setName('');
    }
  };
  const removeCoordinator = (coordinatorEmail: string) => {
    const newCoordinators = coordinators.filter(coordinator => coordinator.email !== coordinatorEmail);
    setCoordinators(newCoordinators);
  };

  useEffect(() => {
    const state = [...defaultValue];
    setCoordinators(state);
  }, [defaultValue, open, editable]);

  useCtrlEnterToRunCallback(updateValue, open);

  return (
    <>
      <div title={title} css={editable ? style.editableField : style.field} onClick={() => editable && setOpen(true)}>
        <Icon name={icon} className={className} />
        <div css={style.valueContainer}>
          <span css={style.value}> {coordinators?.map(c => c.name).join(', ') || ''} </span>
          <span css={style.subTitle}>{title}</span>
        </div>
        {editable && (
          <div css={style.editIcon}>
            <Icon name='pencil' />
          </div>
        )}
      </div>
      <Modal css={style.modal} onClose={() => onCancel()} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content css={style.modalContent}>
          <div css={style.addCoordinatorForm}>
            <div css={style.input}>
              <AdUserDropdownField
                placeholder={t('users.search') || ''}
                onChange={onAdUserChange}
                error={!isValidForm && emailErrorMessage !== ''}
                cornerIcon='search'
                value={email}
                disabled={coordinatorLimitReached}
                assessmentBrand={brand || (assessment?.store?.brand?.code as BrandCode)}
                showJobTitle
              />
            </div>
            <div css={style.nameContainer}>
              <Input
                value={name}
                type='text'
                label={{ icon: 'user' }}
                disabled
                labelPosition='left corner'
                placeholder='Name'
              />
            </div>
            <div css={style.nameContainer}>
              <Input
                value={role}
                type='text'
                label={{ icon: 'shopping bag' }}
                onChange={event => onChangeRole(event)}
                labelPosition='left corner'
                placeholder='Role'
                disabled={coordinatorLimitReached || email === ''}
              />
              {!isValidForm && roleErrorMessage !== '' && (
                <Label basic color='red' pointing>
                  {roleErrorMessage}
                </Label>
              )}
            </div>
            <div css={style.formButtons}>
              <Button
                css={style.button}
                disabled={!validateCoordinator()}
                onClick={() => {
                  const isValid = validateCoordinator();
                  setIsValidForm(isValid);
                  if (isValid) {
                    const newCoordinators = [...coordinators, { name, email, role }];
                    setCoordinators(newCoordinators);
                    clearFields();
                  }
                }}
                icon='plus'
                content='Add'
              />
            </div>
          </div>

          <div css={style.actionPlanCoordinatorList}>
            {coordinators?.map(c => (
              <div key={c.email} css={style.actionPlanCoordinatorItem}>
                <Icon name='user' />
                <div css={style.actionPlanCoordinatorInfo}>
                  <div css={style.actionPlanCoordinatorEmail}>{c.email}</div>
                  <div css={style.actionPlanCoordinatorName}>{c.name}</div>
                  <div css={style.actionPlanCoordinatorRole}>{c.role}</div>
                </div>
                <Button icon onClick={() => removeCoordinator(c.email)}>
                  <Icon name='remove' />
                </Button>
              </div>
            ))}
          </div>
          <div css={style.disclaimer}>{t('assessment.brandCoordinatorList.disclaimer')}</div>
        </Modal.Content>
        <Modal.Actions css={style.actions}>
          {editable && (
            <Button css={style.button} onClick={onCancel}>
              <Icon name='cancel' /> Cancel
            </Button>
          )}
          <Button css={style.button} onClick={() => updateValue()}>
            <Icon name='checkmark' /> Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
