import { Brand, toBrand } from '../../../shared/model/brand.model';
import { FollowUpStatus } from '../../assessments/model/assessmentDetail.model';
import { Auditor, BrandCoordinator } from '../../assessments/model/assessment';
import { Contact } from '../../users/model/user.model';

export interface AssessmentBulkUpdate {
  id: string;
  status: string;
  brand: Brand;
  region: string;
  country: string;
  city: string;
  hfmCode: string;
  jdaCode: string;
  locationName: string;
  year: number;
  month: number;
  followUpStatus: string;
  auditors: Auditor[];
  actionPlanCoordinators: BrandCoordinator[];
  distributionList: Contact[];
  readOnlyDistributionList: Contact[];
}

export const toAssessmentBulkUpdate = (assessment: Record<string, unknown>): AssessmentBulkUpdate => {
  return {
    id: assessment.id as string,
    status: assessment.status as string,
    brand: toBrand(assessment.brand as string),
    region: assessment.region as string,
    country: assessment.country as string,
    city: assessment.city as string,
    hfmCode: assessment.hfmCode as string,
    jdaCode: assessment.jdaCode as string,
    locationName: assessment.locationName as string,
    year: assessment.year as number,
    month: assessment.month as number,
    followUpStatus:
      assessment.followUpStatus != null ? (assessment.followUpStatus as FollowUpStatus) : FollowUpStatus.EMPTY,
    auditors: assessment.auditors as Auditor[],
    actionPlanCoordinators: assessment.actionPlanCoordinators as BrandCoordinator[],
    distributionList: assessment.distributionList as Contact[],
    readOnlyDistributionList: assessment.readOnlyDistributionList as Contact[],
  };
};
export interface AssessmentBulkUpdateAuditorsEmailsRequest {
  updates: AssessmentBulkUpdateNewAuditorsEmails[];
}

export interface AssessmentBulkUpdateNewAuditorsEmails {
  id: string;
  auditorEmails: string[];
}

export interface AssessmentBulkUpdateDistributionListRequest {
  updates: AssessmentBulkUpdateNewDistributionList[];
}

export interface AssessmentBulkUpdateNewDistributionList {
  id: string;
  distributionList: string[];
}

export interface AssessmentBulkUpdateReadOnlyDistributionListRequest {
  updates: AssessmentBulkUpdateNewReadOnlyDistributionList[];
}

export interface AssessmentBulkUpdateNewReadOnlyDistributionList {
  id: string;
  readOnlyDistributionList: string[];
}

export interface UpdateBrandCoordinatorRequest {
  name: string;
  email: string;
  role: string;
}

export interface AssessmentBulkUpdateBrandCoordinatorsRequest {
  updates: AssessmentBulkUpdateNewBrandCoordinators[];
}

export interface AssessmentBulkUpdateNewBrandCoordinators {
  id: string;
  brandCoordinatorList: UpdateBrandCoordinatorRequest[];
}

export enum AssessmentBulkUpdateWizardStep {
  SELECT_FIELD_TO_EDIT = 'SELECT_FIELD_TO_EDIT',
  SELECT_ACTION = 'SELECT_ACTION',
  SELECT_VALUE_TO_REPLACE = 'SELECT_VALUE_TO_REPLACE',
  SELECT_NEW_VALUE = 'SELECT_NEW_VALUE',
  CONFIRM_UPDATE = 'CONFIRM_UPDATE',
}

export enum AssessmentBulkUpdateWizardAction {
  ADD = 'ADD',
  CLEAN = 'CLEAN',
  REPLACE = 'REPLACE',
  FIND_AND_REPLACE = 'FIND_AND_REPLACE',
}

export enum AssessmentBulkUpdateWizardField {
  AUDITORS = 'AUDITORS',
  ACTION_PLAN_COORDINATORS = 'ACTION_PLAN_COORDINATORS',
  DISTRIBUTION_LIST = 'DISTRIBUTION_LIST',
  READ_ONLY_DISTRIBUTION_LIST = 'READ_ONLY_DISTRIBUTION_LIST',
}

export interface AssessmentBulkUpdateWizardSelectedValue {
  name: string;
  email: string;
  role?: string;
  jobTitle?: string;
}
