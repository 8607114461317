/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import style from './auditDetail.style';
import featureStyle from './feature.style';
import {
  ACTION_PLAN_STATUS,
  ActionPlanUpdateRequest,
  Audit,
  FollowUpStatus,
  FollowUpSubmitRequest,
  FollowUpUpdateRequest,
  hasActionPlanCoordinatorNote,
  hasActionPlanCoordinatorNoteByProcess,
  hasAuditorFollowUpNote,
  hasAuditorFollowUpNoteByProcess,
  hasAuditorNote,
  hasAuditorNoteByProcess,
  hasManagerNote,
  hasManagerNoteByProcess,
  hasRegionalManagerNote,
  hasRegionalManagerNoteByProcess,
  isProcessAttachmentsToBeChecked,
  isProcessPassed,
  Priority,
  Process,
  ResultStatus,
  ResultUpdateRequest,
  SubProcess,
} from '../model/assessmentDetail.model';
import { selectPrincipal } from '../../auth/store/principalSlice';
import { ModalPage } from '../../../shared/ModalPage';
import { CheckList } from './CheckList';
import { ProcessItem } from './ProcessItem';
import { Status, STATUS } from '../model/assessmentStatus';
import { AUDITOR_STAFF_ROLES, checkRole, checkRoles, ROLES } from '../../auth/model/principal.model';
import { CheckListAndActionPlanContainer } from './CheckListAndActionPlanContainer';
import {
  saveActionPlan,
  saveFollowUp,
  saveSubProcessResult,
  selectIsActionPlanEditor,
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  selectIsInDistributionList,
  submitFollowUp,
} from '../store/assessmentDetailSlice';
import { toastService } from '../../../core/services/toastService';
import { useAppDispatch } from '../../../core/store';
import { AuditMultiToggle } from './AuditMultiToggle';
import { TopicContainer } from './TopicContainer';
import { setUnsavedChangesPresence } from '../../../core/unsavedChangesSlice';
import { isValidFollowUpStatus } from '../model/assessmentFollowUp.model';

interface Props {
  audit: Audit | null;
  assessmentStatus: Status;
  followUpStatus: FollowUpStatus;
}

export const StandardAuditDetail = ({ audit, assessmentStatus, followUpStatus }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const principal = useSelector(selectPrincipal);
  const isAuditor = useMemo(() => checkRole(principal, ROLES.AUDITOR), [principal]);
  const isAuditorManager = useMemo(() => checkRole(principal, ROLES.AUDITOR_MANAGER), [principal]);
  const isAuditAdmin = useMemo(() => checkRole(principal, ROLES.AUDIT_ADMIN), [principal]);
  const isInDistributionList = useSelector(selectIsInDistributionList);

  const isActionPlanCoordinator = useSelector(selectIsBrandCoordinator);
  const isActionPlanEditor = useSelector(selectIsActionPlanEditor);
  const isAPCoordinator = useSelector(selectIsBrandCoordinator);

  const [onlyFindingsFilter, setOnlyFindingsFilter] = useState(false);
  const [onlyFollowUpFilter, setOnlyFollowUpFilter] = useState(false);
  const [onlyFollowUpToFillInFilter, setOnlyFollowUpToFillInFilter] = useState(false);
  const isFollowUpToFillInToggleVisible = followUpStatus === FollowUpStatus.UNDER_FOLLOWUP;

  const [isTopicContainerActive, setIsTopicContainerActive] = useState<boolean>(false);
  const [priorityFilter, setPriorityFilter] = useState<Priority[]>([]);

  const isFollowUpToggleVisible =
    (followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
      (isAuditAdmin || isActionPlanCoordinator || isActionPlanEditor || isInDistributionList)) ||
    ([FollowUpStatus.DONE, FollowUpStatus.PENDING, FollowUpStatus.COMPLETED].includes(followUpStatus) &&
      (isAuditAdmin ||
        isActionPlanCoordinator ||
        isActionPlanEditor ||
        isInDistributionList ||
        isAuditor ||
        isAuditorManager));

  const isFindingsToggleVisible = isAuditor || isAuditorManager || isAuditAdmin;
  const isToggleFieldVisible = isFindingsToggleVisible || isFollowUpToggleVisible;

  const filterByFollowUp = useCallback((subProcess: SubProcess): boolean => {
    return subProcess.followUp != null && subProcess.followUp.result != null;
  }, []);

  const canEditFollowUp = useCallback(
    (subProcess: SubProcess): boolean => {
      const isAPEditor = principal.email != null && subProcess.actionPlan?.editor?.email === principal.email;
      return (
        subProcess.followUp != null &&
        isValidFollowUpStatus(followUpStatus) &&
        subProcess.actionPlan?.status === ACTION_PLAN_STATUS.SCHEDULED &&
        followUpStatus === FollowUpStatus.UNDER_FOLLOWUP &&
        (isAPCoordinator || isAPEditor || isAuditAdmin || isInDistributionList) &&
        !subProcess.followUp?.completed
      );
    },
    [followUpStatus, isAPCoordinator, isAuditAdmin, isInDistributionList, principal.email]
  );

  const filterByFollowUpToFillIn = useCallback(
    (subProcess: SubProcess): boolean => {
      return canEditFollowUp(subProcess);
    },
    [canEditFollowUp]
  );

  const getFilteredProcesses = useCallback(() => {
    if (audit == null) {
      return [];
    }
    return audit.processes.filter(p =>
      p.subProcesses.some(
        sp =>
          (!onlyFindingsFilter || sp.result.status === ResultStatus.FAILED) &&
          (!onlyFollowUpFilter || filterByFollowUp(sp)) &&
          (!onlyFollowUpToFillInFilter || filterByFollowUpToFillIn(sp)) &&
          (priorityFilter.length === 0 || priorityFilter.some(pf => pf === sp.result.priority || pf == null))
      )
    );
  }, [
    audit,
    onlyFindingsFilter,
    onlyFollowUpFilter,
    onlyFollowUpToFillInFilter,
    filterByFollowUp,
    filterByFollowUpToFillIn,
    priorityFilter,
  ]);

  const getFilteredSubProcess = useCallback(
    (p: Process) =>
      p.subProcesses.filter(
        sp =>
          (!onlyFindingsFilter || sp.result.status === ResultStatus.FAILED) &&
          (!onlyFollowUpFilter || filterByFollowUp(sp)) &&
          (!onlyFollowUpToFillInFilter || filterByFollowUpToFillIn(sp)) &&
          (priorityFilter.length === 0 || priorityFilter.some(pf => pf === sp.result.priority || pf == null))
      ),
    [
      onlyFindingsFilter,
      onlyFollowUpFilter,
      onlyFollowUpToFillInFilter,
      filterByFollowUp,
      filterByFollowUpToFillIn,
      priorityFilter,
    ]
  );

  const onChangeTopicActive = () => {
    setIsTopicContainerActive(!isTopicContainerActive);
    setActiveIndex(-1);
  };

  const onChangeProcessActive = (index: number) => {
    setIsTopicContainerActive(false);
    setActiveIndex(index);
  };

  return audit != null ? (
    <>
      {isToggleFieldVisible && (
        <div css={style.findingsFilter}>
          <AuditMultiToggle
            editable
            defaultFindingsValue={onlyFindingsFilter}
            defaultFollowUpValue={onlyFollowUpFilter}
            defaultFollowUpToFillInValue={onlyFollowUpToFillInFilter}
            defaultPriorityFilter={priorityFilter}
            showFindings
            showFollowUp={isFollowUpToggleVisible}
            showFollowUpToFillIn={isFollowUpToFillInToggleVisible}
            onFindingsChange={value => {
              setOnlyFindingsFilter(value);
            }}
            onFollowUpChange={value => {
              setOnlyFollowUpFilter(value);
            }}
            onFollowUpToFillInChange={value => {
              setOnlyFollowUpToFillInFilter(value);
            }}
            onPriorityFilterChange={value => {
              setPriorityFilter(value);
            }}
          />
        </div>
      )}
      <div css={style.body}>
        {getFilteredProcesses().map((p, index) => (
          <ProcessItem
            process={p}
            index={index}
            key={p.code}
            activeIndex={activeIndex}
            setActiveIndex={onChangeProcessActive}
            icon={
              <>
                {hasManagerNoteByProcess(p) ? <Icon name='sticky note' css={style.noteIcon} /> : <></>}
                {hasAuditorNoteByProcess(p) && <Icon name='sticky note' css={style.auditorNoteIcon} />}
                {hasAuditorFollowUpNoteByProcess(p) && <Icon name='sticky note' css={style.auditorFollowUpNoteIcon} />}
                {hasRegionalManagerNoteByProcess(p) && <Icon name='sticky note' css={style.regionalManagerNoteIcon} />}
                {hasActionPlanCoordinatorNoteByProcess(p) && (
                  <Icon name='sticky note' css={style.actionPlanCoordinatorNoteIcon} />
                )}
                {(isAuditor || isAuditorManager || isAuditAdmin) && isProcessAttachmentsToBeChecked(p) && (
                  <Icon className='fas fa-unlink' css={style.notMandatoryAttachmentsIcon} />
                )}
                {(isAuditor || isAuditorManager || isAuditAdmin) &&
                  (isProcessPassed(p) ? (
                    <Icon name='clipboard check' css={style.successIcon} />
                  ) : (
                    <Icon name='clipboard list' css={style.errorIcon} />
                  ))}
              </>
            }>
            {getFilteredSubProcess(p).map(sp => (
              <ReviewSubProcessContainer
                key={sp.code}
                subProcess={sp}
                assessmentStatus={assessmentStatus}
                followUpStatus={followUpStatus}
              />
            ))}
          </ProcessItem>
        ))}
        {getFilteredProcesses().length === 0 && <div css={style.noItems}>{t('assessment.audit.noFindings')}</div>}
        <TopicContainer onChangeTopicActive={onChangeTopicActive} isTopicContainerActive={isTopicContainerActive} />
      </div>
    </>
  ) : (
    <></>
  );
};

interface SubProcessItemProps {
  subProcess: SubProcess;
  assessmentStatus: Status;
  followUpStatus: FollowUpStatus;
}

const ReviewSubProcessContainer = ({
  subProcess,
  assessmentStatus,
  followUpStatus,
}: SubProcessItemProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectPrincipal);
  const [isCheckListOpen, setCheckListOpen] = useState(false);
  const isAuditor = useCallback(() => checkRoles(user, AUDITOR_STAFF_ROLES), [user]);
  const isAuditorOwner = useSelector(selectIsAuditorOwner);

  const canEditCheckList = useMemo(
    () => assessmentStatus.code !== STATUS.CLOSED.code && checkRole(user, ROLES.AUDIT_ADMIN),
    [assessmentStatus.code, user]
  );

  const canManagerEdit = useCallback(
    () => assessmentStatus.code === STATUS.UNDER_REVIEW.code && checkRole(user, ROLES.AUDITOR_MANAGER),
    [user, assessmentStatus]
  );

  const canEditAuditorNote = useCallback(() => {
    return (
      subProcess.actionPlan != null &&
      (checkRole(user, ROLES.AUDITOR_MANAGER) || isAuditorOwner) &&
      STATUS.ACTIONS_PLAN_UNDER_REVIEW.code === assessmentStatus.code
    );
  }, [subProcess.actionPlan, user, isAuditorOwner, assessmentStatus.code]);

  const handleOnSave = useCallback(
    async (request: ResultUpdateRequest) => {
      const response = await dispatch(saveSubProcessResult(request));
      if (response != null) toastService.success();
      setCheckListOpen(false);
    },
    [dispatch]
  );

  const handleOnSaveFollowUp = useCallback(
    async (request: FollowUpUpdateRequest) => {
      const response = await dispatch(saveFollowUp(request));
      if (response != null) {
        await dispatch(setUnsavedChangesPresence(false));
        toastService.success();
      } else setCheckListOpen(true);
    },
    [dispatch]
  );

  const handleOnSaveActionPlan = useCallback(
    async (request: ActionPlanUpdateRequest) => {
      const response = await dispatch(saveActionPlan(request));
      if (response != null) {
        toastService.success();
      } else {
        setCheckListOpen(true);
      }
    },
    [dispatch]
  );

  const handleOnSubmitFollowUp = useCallback(
    async (updateRequest: FollowUpUpdateRequest, submitRequest: FollowUpSubmitRequest) => {
      const response = (await dispatch(saveFollowUp(updateRequest))) && (await dispatch(submitFollowUp(submitRequest)));
      if (response != null) {
        toastService.success();
        setCheckListOpen(false);
      }
    },
    [dispatch]
  );

  return (
    <>
      {isCheckListOpen && (
        <ModalPage onClose={() => setCheckListOpen(false)} title={`${subProcess.code} ${subProcess.description}`}>
          {subProcess.actionPlan != null ? (
            <CheckListAndActionPlanContainer
              subProcess={subProcess}
              assessmentStatus={assessmentStatus}
              onChecklistSave={handleOnSave}
              onFollowUpSave={handleOnSaveFollowUp}
              onFollowUpSubmit={handleOnSubmitFollowUp}
              followUpStatus={followUpStatus}
              onActionPlanSave={handleOnSaveActionPlan}
            />
          ) : (
            <CheckList
              result={subProcess.result}
              subProcessInfo={subProcess.info}
              assessmentStatus={assessmentStatus}
              processCode={subProcess.processCode}
              subProcessCode={subProcess.code}
              subProcessDescription={subProcess.description}
              onSave={canManagerEdit() || canEditCheckList ? handleOnSave : undefined}
              readonly={!canEditCheckList}
            />
          )}
        </ModalPage>
      )}
      <div css={style.subProcess} onClick={() => setCheckListOpen(true)}>
        {canManagerEdit() || canEditAuditorNote() || canEditCheckList ? (
          <Icon name='pencil' css={style.subProcessActionIcon} />
        ) : (
          <Icon name='search' css={style.subProcessActionIcon} />
        )}
        <div css={style.subProcessDescription}>
          {subProcess.code} - {subProcess.description}
        </div>
        {hasManagerNote(subProcess) && <Icon name='sticky note' css={style.noteIcon} />}
        {hasAuditorNote(subProcess) && <Icon name='sticky note' css={style.auditorNoteIcon} />}
        {hasAuditorFollowUpNote(subProcess) && <Icon name='sticky note' css={style.auditorFollowUpNoteIcon} />}
        {hasRegionalManagerNote(subProcess) && <Icon name='sticky note' css={style.regionalManagerNoteIcon} />}
        {hasActionPlanCoordinatorNote(subProcess) && (
          <Icon name='sticky note' css={style.actionPlanCoordinatorNoteIcon} />
        )}
        {isAuditor() &&
          ((subProcess.actionPlan != null && subProcess.actionPlan.attachmentsToBeChecked) ||
            (subProcess.followUp != null &&
              subProcess.followUp.result != null &&
              subProcess.followUp.attachmentsToBeChecked)) && (
            <Icon className='fas fa-unlink' css={style.notMandatoryAttachmentsIcon} />
          )}
        {subProcess.result.status === ResultStatus.FAILED ? (
          <Icon className='fas fa-circle' css={featureStyle.priorityIconColor(subProcess.result.priority)} />
        ) : (
          isAuditor() && <Icon name='clipboard check' css={style.successIcon} />
        )}
      </div>
    </>
  );
};
